import * as THREE from 'three'

import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js'

/**
 * Loaders.
 */
const loadingManager = new THREE.LoadingManager()

const audio = new Audio('/music/christmas.mp3')
audio.loop = true
audio.volume = 0.2

/**
 * Elements to hide and display on load.
 */
const loadingSection = document.querySelector('.loading-screen')
const christmasCardSection = document.querySelector('.christmas-card')

const textureLoader = new THREE.TextureLoader(loadingManager)
const gltfLoader = new GLTFLoader(loadingManager)

loadingManager.onLoad = () => {
    loadingSection.style.display = 'none'
    christmasCardSection.style.display = 'block'
    audio.play()
}

/**
 * Textures.
 */
const snowTexture = textureLoader.load('textures/particles/snow.png')

/**
 * Screen sizes.
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

/**
 * Canvas.
 */
const canvas = document.querySelector('canvas.webgl')

/**
 * Scene.
 */
const scene = new THREE.Scene()
scene.background = new THREE.Color('#020024')

/**
 * Building scene with models.
 */
const christmasScene = new THREE.Group()
scene.add(christmasScene)

let christmasModel = null
gltfLoader.load(
    'models/christmas-scene/scene.gltf',
    (gltf) => {
        christmasModel = gltf.scene.children[0]
        
        if (1200 <= sizes.width && sizes.width < 1500) {
            christmasModel.scale.set(0.07, 0.07, 0.07)
            christmasModel.position.set(1.5, 0, 0)
        } else if (430 <= sizes.width && sizes.width < 1200) {
            christmasModel.scale.set(0.05, 0.05, 0.05)
            christmasModel.position.set(0, 0.5, 0)
        } else if (sizes.width < 430) {
            christmasModel.scale.set(0.05, 0.05, 0.05)
            christmasModel.position.set(0, 1, 0)
        } else {
            christmasModel.scale.set(0.1, 0.1, 0.1)
            christmasModel.position.set(1.5, 0, 0)
        }
        christmasModel.rotation.x = -Math.PI / 2.5
        christmasModel.rotation.z = Math.PI

        christmasScene.add(christmasModel)
    }
)

/**
 * Snowflakes particles.
 */
const snowflakesCount = 300
const particlePositions = new Float32Array(snowflakesCount * 3)

const particlesGeometry = new THREE.BufferGeometry()
const particlesMaterial = new THREE.PointsMaterial({
    color: 'white',
    size: 0.2,
    sizeAttenuation: true,
    depthWrite: false,
    transparent: true,
    alphaMap: snowTexture,
    opacity: 0.3
})

for(let i = 0; i < snowflakesCount; i++) {
    const i3 = i * 3

    particlePositions[i3] = (Math.random() - 0.5) * 10
    particlePositions[i3 + 1] = (Math.random() - 0.5) * 10
    particlePositions[i3 + 2] = (Math.random() - 0.5) * 10
}

particlesGeometry.setAttribute('position', new THREE.BufferAttribute(particlePositions, 3))

const points = new THREE.Points(
    particlesGeometry,
    particlesMaterial
)
scene.add(points)

/**
 * Resize.
 */
window.addEventListener('resize', () =>
{
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    if (1200 <= sizes.width && sizes.width < 1500) {
        christmasModel.scale.set(0.07, 0.07, 0.07)
        christmasModel.position.set(1.5, 0, 0)
    } else if (430 <= sizes.width && sizes.width < 1200) {
        christmasModel.scale.set(0.05, 0.05, 0.05)
        christmasModel.position.set(0, 0.5, 0)
    } else if (sizes.width < 430) {
        christmasModel.scale.set(0.03, 0.03, 0.03)
        christmasModel.position.set(0, 0.5, 0)
    } else {
        christmasModel.scale.set(0.1, 0.1, 0.1)
        christmasModel.position.set(1.5, 0, 0)
    }

    if (sizes.width < 1200) {
        directionalLight.position.set(-2, 2, 1.5)
    } else {
        directionalLight.position.set(-1.5, 1, 1.5)
    }

    if (sizes.width < 1200) {
        pointLight1.position.set(-1.2, 1, 0)
    } else {
        pointLight1.position.set(0, 0, 0)
    }

    if (sizes.width < 1200) {
        pointLight2.position.set(0.5, 1, 0)
    } else {
        pointLight2.position.set(2, 0, 0)
    }

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
const camera = new THREE.PerspectiveCamera(35, sizes.width / sizes.height, 0.1, 100)
camera.position.z = 6

const directionalLight = new THREE.DirectionalLight('#ffffff', 3)
if (sizes.width < 1200) {
    directionalLight.position.set(-2, 2, 1.5)
} else {
    directionalLight.position.set(-1.5, 1, 1.5)
}

const pointLight1 = new THREE.PointLight('red', 30)
if (sizes.width < 1200) {
    pointLight1.position.set(-1.2, 1, 0)
} else {
    pointLight1.position.set(0, 0, 0)
}
christmasScene.add(pointLight1)

const pointLight2 = new THREE.PointLight('#092e0a', 30)
if (sizes.width < 1200) {
    pointLight2.position.set(0.5, 1, 0)
} else {
    pointLight2.position.set(2, 0, 0)
}
christmasScene.add(pointLight2)

christmasScene.add(directionalLight)

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

const cursor = {
    x: 0,
    y: 0
}

window.addEventListener('mousemove', (event) => {
    cursor.x = event.clientX / sizes.width - 0.5
    cursor.y = event.clientY / sizes.height - 0.5 
})


/**
 * Animate.
 */
const clock = new THREE.Clock()
let previousTime = 0

/**
 * Render animations.
 */
const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime

    /**
     * Render.
     */
    renderer.render(scene, camera)

    /**
     * Animate snow.
     */
    for(let i = 0; i < snowflakesCount; i++) {
        const i3 = i * 3
        particlesGeometry.attributes.position.array[i3] = Math.tan(elapsedTime + i3) * 1
        particlesGeometry.attributes.position.array[i3 + 1] = Math.sin((elapsedTime + i3) / 20)
    }
    particlesGeometry.attributes.position.needsUpdate = true

    /**
     * Animate model.
     */
    if (christmasModel) {
        if (sizes.width < 1200) {
            christmasModel.position.y = Math.min(Math.sin(elapsedTime) / 10, 0.5) + 0.5
            christmasModel.rotation.y = Math.sin(elapsedTime) / 10
        } else {
            christmasModel.position.y = Math.min(Math.sin(elapsedTime) / 2.5, 0.7)
            christmasModel.rotation.y = Math.sin(elapsedTime) / 10
        }
    }

    const parallaxX = cursor.x
    const palallaxY = -cursor.y * 0.5
    camera.position.x += (parallaxX - camera.position.x) * 3.5 * deltaTime
    camera.position.y += (palallaxY - camera.position.y) * 3.5 * deltaTime

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()